<template>
<keep-alive>


  <div id="container">
      <p id="warn"></p>
      <input id="numberInput" v-on:click="block($event)" v-bind:class="[ inputClass ]">
    <ul id="keyboard">
        <li class="letter" v-on:click="click($event)" id="1">1</li>
        <li class="letter" v-on:click="click($event)" id="2">2</li>
        <li class="letter" v-on:click="click($event)" id="3">3</li>
        <li class="letter clearl" v-on:click="click($event)" id="4">4</li>
        <li class="letter" v-on:click="click($event)" id="5">5</li>
        <li class="letter" v-on:click="click($event)" id="6">6</li>
        <li class="letter clearl" v-on:click="click($event)" id="7">7</li>
        <li class="letter " v-on:click="click($event)" id="8">8</li>
        <li class="letter" v-on:click="click($event)" id="9">9</li>

        <li class="letter clearl" @touchstart="click($event)" @mousedown="click($event)" @mouseup="delUp()" id="back">◀</li>

        <li class="letter" v-on:click="click($event)" id="0">0</li>
         <li class="ok" v-on:click="click($event)" id="ok">OK</li>
    </ul>
</div>
</keep-alive>
</template>

<script>



export default {
  name: 'Keypad',
  props: {
  },
  data: () => {
      return {
          clickActive: false,
          inputClass: 'inputStart'
      }
  },
  methods: {

      click(event) {
          const field = document.getElementById("numberInput");
          let warn = document.getElementById("warn");
          if (this.clickActive) return;
          let targetId = event.currentTarget.id;

          if (targetId === "ok") {

              // CAN BE REMOVED AFTER TESTING:
            if (field.value == "9") {
                field.value = '94260789'
                  warn.innerHTML = "";
                  this.inputClass = 'inputOk';
                  let mobileNumber = field.value;
                  this.$store.commit('setCurrentMobile', mobileNumber)
                  this.$router.push({ name: 'Shop'})
                  console.log(field.value);
                  field.value = "";
              }

              if (field.value == "4") {
                field.value = '48375685'
                  warn.innerHTML = "";
                  this.inputClass = 'inputOk';
                  let mobileNumber = field.value;
                  this.$store.commit('setCurrentMobile', mobileNumber)
                  this.$router.push({ name: 'Shop'})
                  console.log(field.value);
                  field.value = "";
              }



              ////////////////////////////////
              if (field.value.length < 8) {
                  warn.innerHTML = "Nummeret må være 8 siffer!";
                  this.inputClass = 'inputErr';
              } else if (
                  field.value.length === 8 &&
                  field.value.charAt(0) === '4' ||
                  field.value.charAt(0) === '9') {

                  warn.innerHTML = "";
                  this.inputClass = 'inputOk';
                  let mobileNumber = field.value;
                  this.$store.commit('setCurrentMobile', mobileNumber)
                  this.$router.push({ name: 'Shop'})
                  console.log(field.value);
                  field.value = "";
              } else {
                  warn.innerHTML = ""
                  this.inputClass = 'inputStart';
              }

          } else if (targetId === "back") {
              warn.innerHTML = "";
              this.inputClass = 'inputStart';
              this.clickActive = true;
              this.startTimer();
              field.value = field.value.slice(0, -1);
          } else if (field.value.length >= 8) {
              warn.innerHTML = "Maks 8 siffer!";
          } else {
              warn.innerHTML = "";
              this.inputClass = 'inputStart';
              field.value = field.value + targetId;
          }

          // Warn if first number is not 4 or 9 (Valid norwegian phone number)
          let fn = field.value.charAt(0);
          if (fn !== '4' &&
              fn !== '9' &&
              fn !== '' &&
              warn.innerHTML === "" ) {
                  warn.innerHTML = "Gyldig mobilnummer begynner med 4 eller 9";
                  this.inputClass = 'inputErr';
              }

        if (
                field.value.length === 8 &&
                field.value.charAt(0) === '4' ||
                field.value.length === 8 &&
                field.value.charAt(0) === '9') {
                    this.inputClass = 'inputOk';
            }

      },

      delUp() {
          this.clickActive = false
          this.stopTimer();
      },

      delAll() {
          const field = document.getElementById("numberInput");
          if (this.clickActive) {
              field.value = "";
              document.getElementById("warn").innerHTML = "";
              this.inputClass = 'inputStart';
          }
          this.clickActive = false;
      },

      startTimer() {
          this.timer = setTimeout(this.delAll, 500);
      },

      stopTimer() {
          clearTimeout(this.timer);
      },

      block(event) {
          event.preventDefault();
      }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 250px;
    height: 50px;
    border:2px solid #aaa;
    border-radius:10px;
    margin:8px 0;
    outline:none;
    border-color:rgb(0, 0, 0);
    font-size:30px;
    text-align: center;
    font-weight: bold;
    pointer-events:none;
    user-select: none;
  }

.inputStart {
    box-shadow:0 0 8px 0 rgb(255, 255, 0);
}

.inputOk {
    box-shadow:0 0 8px 0 rgb(0, 255, 13);
}

.inputErr {
    box-shadow:0 0 8px 0 rgb(255, 0, 0);
}


#keyboard {
    padding: 0;
    padding-top: 20px;
    list-style: none;
    align-self: center;
    justify-self: center;
    margin: auto;
    }

#keyboard li {
    user-select: none;
    float: left;
    margin: 2px 2px 2px 2px;
    width: 80px;
    height: 80px;
    font-size: 24px;
    line-height: 80px;
    text-align: center;
    background: rgb(221, 221, 221);
    border: 1px solid #000000;
    border-radius: 5px;
}

.capslock, .tab, .left-shift, .clearl, .switch {
    clear: left;
}

#keyboard li:hover {  
    position: relative;
    top: 1px;
    left: 1px;
    border-color: #7e7e7e;
    cursor: pointer;
}

#keyboard li:active {  
    position: relative;
    top: 2px;
    left: 2px;
    border-color: #7e7e7e;
    background: rgb(243, 243, 243);
    cursor: pointer;
}

#keyboard .ok {  
    user-select: none;
    float: left;
    margin: 2px 2px 2px 2px;
    width: 80px;
    height: 80px;
    font-size: 24px;
    line-height: 80px;
    text-align: center;
    background: rgba(13, 151, 0, 0.658);
    border: 1px solid #000000;
    border-radius: 5px;
}

p {
    color: red;
    font-weight: bold;
    padding: -100px;
    border: -100px;
}

</style>
