<template>
  <div class="home">
    <div class="center">
      <div v-if="this.machineStatus == 'online'">
        <img v-on:click="go($event)" class="cmPng" alt="CM logo" src="../assets/CM.svg">
        <h1>Trykk for å velge automat A1</h1>
      </div>

      <div v-if="this.machineStatus == 'offline'" id="error">
        <img id="errorPng" alt="Error Icon" src="../assets/darkness.png">
        <h1>Maskinen er midlertidig ute av drift!</h1>
      </div>

    </div>
  </div>
</template>


<script>

const axios = require('axios');

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    machineStatus: 'online'
  }),
  created() {
    let orderId = localStorage.getItem('orderId')
    let selectedItem = JSON.parse(localStorage.getItem('selectedItem'));
    console.log('OLD ORDER! -> ', orderId);
    console.log('TYPE: -> ', typeof orderId);
    if (orderId === 'false' || orderId === '') {
      orderId = false;
      }
    if (orderId) {
      this.$store.commit('setSelectedItem', selectedItem);
      this.$store.commit('setCurrentOrderId', orderId);
      this.$router.push('Shop');
    }
    axios.get(`https://gimegbrus.no/api/machine/status/A1`)
        .then(res => {
          console.log('AXOIS RESPONSE: ', res)
          this.machineStatus = res.data;
        })
        .catch(err => {
          console.log('AXOIS RESPONSE: ', err)
        })
  },
  methods: {
    go() {
      this.$store.commit('resetCurrentData');
      this.$store.commit('setCurrentMachine', 'A1');
      this.$store.commit('setCurrentMobile', false);
      this.$router.push({ name: 'Shop'})
    }
  }
}
</script>

<style scoped>

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.cmPng {
  flex-basis: 200px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  max-width: 200px;
}

.center {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
  
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

#error {
  margin-top: auto;
  margin-bottom: auto;
}

#errorPng {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

</style>
