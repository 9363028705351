<template>
  <div id="admin">
    <div id="center">
      <img id="cmPng" alt="CM logo" src="../assets/CM.svg">
      <h1>Admin page</h1>
        <div class="mainContainer">
          <div class="wrapper">
            <form class="form-signin">       
              <h3 class="form-signin-heading" style="color: red">You are not logged in.</h3>
              <input id="userInput" type="text" class="form-control" name="username" placeholder="Username" required="" autofocus="" />
              <input id="passInput" type="password" class="form-control" name="password" placeholder="Password" required=""/>
              <button class="btn btn-lg btn-primary btn-block" v-on:click="login($event)">Login</button>   
            </form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

const axios = require('axios');


export default {
  name: 'Admin',
  components: {
  },

  data: () => {
      return {
          username: null,
          password: null,
      }
  },

  methods: {
    login(e) {
      let username = document.getElementById("userInput").value;
      let password = document.getElementById("passInput").value;
      console.log('Username: ', username);
      e.preventDefault();
      axios({
        method: 'POST',
        url: 'https://gimegbrus.no/api/auth',
        data: {
          email: username,
          password: password
        }
      })
        .then(res => {
          sessionStorage.setItem('token', res.data);
          console.log('Server responded with token: ', res.data)
          window.location.href = `https://gimegbrus.no/admin?token=${res.data}`;
        })
        .catch(err => {
          console.log('AXOIS RESPONSE: ', err)
        })
    }
  },

  activated: function() {
    let data = sessionStorage.getItem('token');
    if (data) {
      window.location.href = `https://gimegbrus.no/admin?token=${data}`;
    }
  },


}
</script>

<style scoped>

#admin {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
#cmPng {
  width: 100px;
  align-self: center;
}

#center {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
  
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
}




.mainContainer {
    display: flex;
    align-content: center;
    justify-content: center;
}

.card{
    margin: 10px;
}

.card-text {
    margin-bottom: 0px;
    white-space: nowrap;
}

.left {
    
    text-align: right;
}

.right {
    text-align: left;
}

.middle {
    text-align: center;
}

.bottom {
    flex-wrap: wrap;
}

.button {
    margin: 10px;
}

.grayscale {
    filter: grayscale(100%);
}

.info {

}

.old {
    text-decoration: line-through;
    color: grey;
}

.sale {
    color: green;

}

.price {
    font-weight: bold;
}

.newPrice {
    text-decoration: underline;
    font-weight: bold;
}

</style>
