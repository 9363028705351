<template>
<keep-alive>


  <div id="shop">

    <div v-if="loading" id="loading">
      <img id="loadPng" alt="Loading Icon" src="../assets/load.svg">
      <h1>Henter produkter...</h1>
    </div>

    <div v-if="error" id="error">
      <img id="errorPng" alt="Loading Icon" src="../assets/err.png">
      <h1>En feil har oppstått!</h1>
      <form>
        <input id="retry" type="submit" value="Prøv på nytt" />
      </form>
    </div>



    <div v-if="!loading && !error" id="loading">
      <Cards />
    </div>


  </div>

</keep-alive>
</template>

<script>

const axios = require('axios');

import Cards from '../components/Cards.vue'
//import Product from './components/Product.vue'

export default {
  name: 'Shop',
  components: {
    Cards,
  },

  data: () => {
    return {
      loading: true,
      error: false,
    }
  },
  
  created: function(){
    
  },

  activated: function(){
    this.checkRoute();
    console.log('this.$store.getters.getCurrentMachine: ', this.$store.getters.getCurrentMachine)
    console.log('this.$store.getters.getCurrentMobile: ', this.$store.getters.getCurrentMobile)
    console.log('this.$store.getters.getCurrentOrderId: ', this.$store.getters.getCurrentOrderId)
    console.log('this.$store.getters.getCurrentStage: ', this.$store.getters.getCurrentStage)
    console.log('this.$store.getters.getCurrentInventory: ', this.$store.getters.getCurrentInventory)
  },
  
  methods: {
    checkRoute() {
      if (this.$store.getters.getCurrentOrderId ||
        this.$store.getters.getCurrentStage) {
        this.$router.push('Vending');
        return;
      }

      if (!this.$store.getters.getCurrentMachine) {
        this.$router.push('Home');
        return;
      }

      if (!this.$store.getters.getCurrentMobile) {
        this.$router.push('Dial');
        return;
      }

      if (this.$store.getters.getCurrentInventory) {
        this.loading = false;
        return;
      }
        console.log('INVENTORY: ', this.$store.getters.getCurrentInventory)
        this.getInventory();

    },

    getInventory() {
      axios.get(`https://gimegbrus.no/api/machine/vipps/${this.$store.getters.getCurrentMobile}/${this.$store.getters.getCurrentMachine}`)
      .then(res => {
        console.log('AXOIS RESPONSE: ', res)
        this.loading = false
        if (!res.data.slots) {
          console.log('No response from axios')
          this.error = true;
          /* this.$store.commit('setCurrentStage', 'error'); */
        } else {
          console.log('Response from axios.')
          this.$store.commit('setCurrentInventory', res.data);
          this.loading = false;
        }
      })
      .catch(err => {
        console.log('AXOIS ERROR!: ', err)
        this.loading = false
        this.error = err
        /* this.$store.commit('setCurrentStage', 'error'); */
      })
    },
  }
}
</script>

<style scoped>

.icon{
  width: 50px;
  cursor: pointer;
}

#shop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  align-content: center;
  align-items: center;
}
#cmPng {
  flex-basis: 200px;
}

#loading {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
  
}

.productImage {
  height: 100px;
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

#retry {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

#error {
  margin-top: auto;
  margin-bottom: auto;
}

#errorPng {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

#navbar {
}

</style>
