<template>
  <div id="dial">
    <div id="center">
      <img id="cmPng" alt="CM logo" src="../assets/vipps.png">
      <h1>Ditt Nummer 📱</h1>
      <keep-alive>
        <Keypad />
      </keep-alive>
      
      
    </div>
  </div>
</template>

<script>
import Keypad from '../components/Keypad';

export default {
  name: 'Dial',
  components: {
    Keypad
  }
}
</script>

<style scoped>

#dial {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
#cmPng {
  width: 300px;
}

#center {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
  
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>
