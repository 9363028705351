import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Dial from '../views/Dial.vue'
import Shop from '../views/Shop.vue'
import Vending from '../views/Vending.vue'
import FAQ from '../views/FAQ.vue'
import Help from '../views/Help.vue'
import Terms from '../views/Terms.vue'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/dial',
        name: 'Dial',
        component: Dial
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/help',
        name: 'Help',
        component: Help
    },
    {
        path: '/shop',
        name: 'Shop',
        component: Shop
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/vending',
        name: 'Vending',
        component: Vending
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin
    },
]

const router = new VueRouter({
    mode: 'abstract',
    base: process.env.BASE_URL,
    routes
})


export default router