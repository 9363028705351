import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentOrderId: false,
    currentMachine: false,
    currentInventory: false,
    selectedItem: false,
    currentMobile: false,
    currentStage: false,
    currentDial: null,


  },
  mutations: {
    resetCurrentData(state, payload) {
      state.currentOrderId = false;
      state.currentMachine = false;
      state.currentInventory = false;
      state.selectedItem = false;
      state.currentMobile = false;
      state.currentStage = false;
      state.currentDial = null;
    },
    setCurrentOrderId(state, payload) {
      state.currentOrderId = payload;
    },
    setCurrentMachine(state, payload) {
      state.currentMachine = payload;
    },
    setCurrentInventory(state, payload) {
      state.currentInventory = payload;
    },
    setSelectedItem(state, payload) {
      state.selectedItem = payload;
    },
    setCurrentMobile(state, payload) {
      state.currentMobile = payload;
    },
    setCurrentStage(state, payload) {
      state.currentStage = payload;
    },
    setCurrentDial(state, payload) {
      state.currentDial = payload;
    },
  },

  actions: {

    setSelectedItem({ commit }, payload) {
      console.log('payload in action: ', payload)
      if (payload.pd) {
        if (payload.pd && payload.pd.disable) {
          payload.pd.disable = true;
        } else {
          payload.pd.disable = false;
        }
      }
      commit('setSelectedItem', payload);
    }


  },

  modules: {},
  getters: {
    getCurrentOrderId(state) {
      return state.currentOrderId
    },
    getCurrentMachine: state => state.currentMachine,
    
    getCurrentInventory: state => state.currentInventory,
    getCurrentSlots: state => state.currentInventory.slots,

    getSelectedItem: state => state.selectedItem,
    getCurrentMobile: state => state.currentMobile,
    getCurrentStage: state => state.currentStage,
    getCurrentDial: state => state.currentDial,
    getCurrentDisable(state) {
      return state.selectedItem
    },
  },
});
