<template>
  <div id="help">
    <div id="center">
      <img id="cmPng" alt="CM logo" src="../assets/CM.svg">
      <h1>Help</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Help',
  components: {
  }
}
</script>

<style scoped>

#help {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
#cmPng {
  flex-basis: 200px;
}

#center {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
  
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>
