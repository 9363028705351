<template>
<keep-alive>
    <div class="container">

        <b-card-group deck>

                <b-card
                v-for="slot of this.$store.getters.getCurrentSlots" :key="slot.itemId"
                no-body class="overflow-hidden"
                style="max-width: 500px; min-width: 400px;"
                align="left">

                    <b-row no-gutters >
                        <b-col class="d-flex align-items-center justify-content-center" md="3">
                                <b-card-img align-self="center" :class="getInfo(slot, 'imageClass')" :src="`https://gimegbrus.no/api/assets/${slot.itemId}.png`" @error="$event.target.src=require('../assets/soda.png')" style="max-height: 200px; width: auto;" alt="Product Image" class="rounded-0"></b-card-img>
                        </b-col>
                        <b-col md="9">
                            <b-card-body>
                                
                                <b-container>

                                <b-row align-h  ="center">
                                    <b-col><b-card-title class="middle">{{slot.item}}</b-card-title></b-col>
                                </b-row>
                                <b-row align-h="center">
                                    <b-col class="left" ><b-card-text>Innhold: </b-card-text></b-col>
                                    <b-col class="right"><b-card-text>{{slot.content}}</b-card-text></b-col>
                                </b-row>
                                <b-row align-h="center">
                                    <b-col class="left" :class="getInfo(slot, 'priceStyle')"><b-card-text>Pris: </b-card-text></b-col>
                                    <b-col class="right" :class="getInfo(slot, 'priceStyle')"><b-card-text class="right">{{slot.price + ',-'}}</b-card-text></b-col>
                                </b-row>
                                <b-row align-h="center" v-if="getInfo(slot, 'hasDiscount')">
                                    <b-col class="left sale"><b-card-text>{{getInfo(slot, 'din/null')}}Rabatt: </b-card-text></b-col>
                                    <b-col class="right sale"><b-card-text>{{getInfo(slot, 'discountType')}}</b-card-text></b-col>
                                </b-row>
                                <b-row align-h="center" v-if="getInfo(slot, 'hasDiscount')">
                                    <b-col class="left newPrice"><b-card-text>{{getInfo(slot, 'din/ny')}} Pris: </b-card-text></b-col>
                                    <b-col class="right newPrice"><b-card-text>{{getInfo(slot, 'newPrice')}},-</b-card-text></b-col>
                                </b-row>
                                <b-row align-h="center">
                                    <b-col class="left" :class="getInfo(slot, 'pawnStyleIfFree')"><b-card-text>+ Pant: </b-card-text></b-col>
                                    <b-col class="right" :class="getInfo(slot, 'pawnStyleIfFree')"><b-card-text>{{slot.pawn}},-</b-card-text></b-col>
                                </b-row>
                                <b-row class="bottom" align-h="center">
                                    <b-button :disabled="getInfo(slot, 'isSold')" class="button" :variant="getInfo(slot, 'buyColor')" v-on:click="purchase(slot)">{{getInfo(slot, 'buyText')}}</b-button>
                                    <b-button v-if="slot.pd && slot.inStock" class="button" pill :variant="getInfo(slot, 'buttonColor')" v-on:click="getInfo(slot, 'discountToggle')">{{getInfo(slot, 'discountButtonText')}}</b-button>
                                </b-row>

                                </b-container>

                            </b-card-body>
                        </b-col>
                    </b-row>
                 </b-card>

        </b-card-group>




    </div>
</keep-alive>
</template>

<script>


export default {
  name: 'Cards',
  props: {
  },
  data: () => {
      return {
          machine: false,
      }
  },
  methods: {


/*       setPd(slot) {
          let inventory = this.$store.getters.getCurrentInventory;
          for (let x of inventory.slots) {
              if (x.itemId == slot.itemId) {
                if (x.pd.disable) {
                    x.pd.disable = false;
                } else {
                    x.pd.disable = true;
                }
              }
          }
          this.$store.commit('setCurrentInventory', inventory);
      }, */


    purchase(slot){
        if (!slot.inStock) return;
        if (slot.pd) {
            console.log('SLOT pd disable: ', slot.pd.disable)
        }
        //console.log('SLOT.PD: ', slot.pd)
        this.$store.dispatch('setSelectedItem', slot);
        localStorage.setItem('selectedItem', JSON.stringify(this.$store.getters.getSelectedItem));
        //console.log('currentItem: ', this.$store.getters.getSelectedItem)

        //console.log('currentDisable: ', this.$store.getters.getCurrentDisable())

        let nd = false;
        if (slot.pd && slot.pd.disable) nd = true;
        
        
        console.log(`{"req":"order", "data": {"payment":"vipps", "mobile":"${this.$store.getters.getCurrentMobile}", "machine":"${this.$store.getters.getCurrentMachine}", "itemId":"${this.$store.getters.getSelectedItem.itemId}", "noDiscount":"${nd}"}}`)
        this.$router.push('Vending');
    },


      getInfo(slot, req) {

          //this.$forceUpdate();

          if (req == 'hasDiscount') {
              if (slot.gd && slot.inStock || slot.pd && !slot.pd.disable && slot.inStock) {
                  return true;
              } else {
                  return false;
              }
          }


          if (req == 'din/null') {
              if (slot.pd && !slot.pd.disable) {
                  return 'Din ';
              } else {
                  return ;
              }
          }
          

          if (req == 'din/ny') {
              if (slot.pd && !slot.pd.disable) {
                  return 'Din';
              } else {
                  return 'Ny';
              }
          }


          if (req == 'newPrice') {
              if (slot.pd && !slot.pd.disable) {
                  return slot.pd.price;
              } else if (slot.gd) {
                  return slot.gd.price;
              }
              return ;
          }


          if (req == 'discountType') {
              let d;
              if (slot.pd && !slot.pd.disable) {
                  d = slot.pd
              } else if (slot.gd) {
                  d = slot.gd
              } else return;


              if (d.type == 'free') {
                  if (d.quantity == 0) {
                      return 'Ubegrenset Gratis!'
                  } else {
                      return d.quantity + ' Gratis Brus!'
                  }
              }

              if (d.type == 'percentage') {
                  return d.quantity + '% Avslag!'
              }

              if (d.type == 'value') {
                  return d.quantity + ' kr. Avslag'
              }

              if (d.type == 'cost') {
                  let add = '';
                  if (d.quantity > 0) {
                      add = '+ ' + d.quantity + ' kr.'
                  }
                  return 'Innkjøpspris ' + add;
              }

              return;
          }


          if (req == 'hasPd') {
              if (slot.pd && slot.inStock) return true;
              return false;
          }

          if (req == 'pdOn') {
              if (slot.pd && slot.pd.disable && slot.inStock) return 'success';
              return 'warning';
          }

          if (req == 'discountButtonText') {
              if (slot.pd && slot.pd.disable) return 'Bruk min rabatt';
              return 'Ikke bruk rabatt';
          }

          if (req == 'buttonColor') {
              if (slot.pd && slot.pd.disable) return 'success';
              return 'warning';
          }
          
          if (req == 'buyText') {
              if (slot.inStock) return 'Gi meg den!';
              return 'UTSOLGT';
          }

          if (req == 'buyColor') {
              if (slot.inStock) return 'primary';
              return 'outline-secondary';
          }

          if (req == 'isSold') {
              if (slot.inStock) return false;
              return true;
          }

          if (req == 'imageClass') {
              if (slot.inStock) return false;
              return 'grayscale';
          }

          if (req == 'pawnStyleIfFree') {
              if (slot.gd && slot.gd.type === 'free' && slot.inStock || slot.pd && !slot.pd.disable && slot.pd.type === 'free' && slot.inStock) return 'old';
              return;
          }

          if (req == 'priceStyle') {
              if (slot.gd && slot.inStock || slot.pd && !slot.pd.disable && slot.inStock) return 'old';
              return 'price';
          }

          if (req == 'discountToggle') {
              if (slot.pd.disable) {
                  slot.pd.disable = false;
              } else if (slot.pd) {
                  slot.pd.disable = true;
              }
              this.$forceUpdate();
          }
          
      },
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
}

.card{
    margin: 10px;
}

.card-text {
    margin-bottom: 0px;
    white-space: nowrap;
}

.left {
    
    text-align: right;
}

.right {
    text-align: left;
}

.middle {
    text-align: center;
}

.bottom {
    flex-wrap: wrap;
}

.button {
    margin: 10px;
}

.grayscale {
    filter: grayscale(100%);
}

.info {

}

.old {
    text-decoration: line-through;
    color: grey;
}

.sale {
    color: green;

}

.price {
    font-weight: bold;
}

.newPrice {
    text-decoration: underline;
    font-weight: bold;
}





</style>
