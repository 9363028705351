<template>

  <div id="app">
    <Navbar />
    <div id="content">

      <keep-alive max="5">
      <router-view :key='$route.fullPath' />
      </keep-alive>
      
    </div>
  </div>
</template>


<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar
  },
  mounted: function() {
    this.$router.push('Home');
  },
  
}
</script>



<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#content {
  padding-top: 100px;
}


</style>
