<template>

  <div id="vending">
    <div v-if="error" id="error">
      <img id="errorPng" alt="Error Icon" src="../assets/err.png" width="200px">
      <h1>En feil har oppstått!</h1>
      <p>{{this.errorMsg}}</p>
      <form>
        <input id="retry" type="submit" value="Prøv på nytt" />
      </form>
    </div>

    <div v-if="!error" id="center">
      <div id="loading">
        <img id="loadPng" alt="Status Icon" :src="cImage" width="100px">
        <h1>{{cStatus}}</h1>
        <p v-if="this.isFree" style="color: red">( Summen blir refundert ved kjøp )</p>
        <p v-if="this.orderId">Ordre ID: {{this.orderId}}</p>
      </div>
      <SelectedCard  :style="this.$store.getters.getCurrentStage == 'canceled' ? '-webkit-filter: grayscale(1)' : ''" />
      
      <div v-if="this.$store.getters.getCurrentStage == 'delivered'" class="deliveredButtons">
        <b-button style="margin: 10px" variant="primary" v-on:click="go('redial')">Nytt Nummer</b-button>
        <b-button style="margin: 10px" variant="primary" v-on:click="go('store')">Til Utvalg</b-button>
      </div>

      <div v-if="this.$store.getters.getCurrentStage == 'canceled'" class="deliveredButtons">
        <b-button style="margin: 10px" variant="warning" v-on:click="go('resend')">Forsøk igjen</b-button>
        <b-button style="margin: 10px" variant="primary" v-on:click="go('store')">Til Utvalg</b-button>
        <b-button style="margin: 10px" variant="danger" v-on:click="go('reset')">Start på nytt</b-button>
      </div>

      <div v-if="this.cancelActive" class="deliveredButtons">
        <b-button style="margin: 10px" variant="danger" v-on:click="go('cancel')">Avbryt Ordre</b-button>
      </div>

    </div>
  </div>
</template>

<script>
// -- Codes: --
// insufficient: Insufficient funds or payment method.
// canceled: Canceled by user.
// timeout: Payment timed out, user did not act on payment.
// reserved: Payment accepted, contacting vending machine.
// error: General error.
// noResponse: No response from vending machine, order will be refunded withing 24h if item was not delivered.
// Vending: Vending selection.
// delivered: Product vended.
// connecting: Connecting to machine.
// payment: Waiting for payment.
// notFound: Order not found or canceled.
// frequency: Too many order requests. Try again later.
// lost: lost connection to server

const axios = require('axios');
const drunk = require('../assets/drunk.png');
const que = require('../assets/que.png');

import SelectedCard from '../components/SelectedCard.vue'


export default {
  name: 'Vending',
  components: {
    SelectedCard,
  },
  data: function() {
    return {
      machine: null,
      mobile: null,
      itemId: null,
      noDiscount: null,
      name: null,
      order: '',
      connection: null, // WS connection object
      newConnection: true, // If connection is new.
      orderId: '',
      error: false,
      errorMsg: false,
      previousStage: null,
      cancelActive: false,
      to: false,
      isFree: false,

      cImage: 'https://gimegbrus.no/api/assets/load.svg',

      cStatus: 'Kobler til...'
    }
  },


  activated: function(){
    if (!this.$store.getters.getCurrentMachine && this.$store.getters.getCurrentOrderId) {
      this.cStatus = 'Henter ordredetaljer...';
      this.cancelActive = false;
      this.newConnection = false;
      this.connect();
      return;
    }

    if (!this.$store.getters.getCurrentStage) {
      console.log('Stage is negative, starting new order')
      this.resetViewData();
      this.checkData();
      this.free();
      this.connect();
    }/*  else {
      this.checkData();
      this.connect();
    }
 */
  },

/* 
  mounted: function(){
    this.checkData();
    //this.registerConnection();
  },

  created () {
    this.connect();
  },

 */



  // METHODS //

  methods: {

    go(state) {
      if (state == 'reset') {
        this.$store.commit('resetCurrentData');
        localStorage.setItem('orderId', false);
        this.$router.push('Home');
      }

      if (state == 'redial') {
        this.$store.commit('resetCurrentData');
        this.$store.commit('setCurrentMachine', this.machine);
        localStorage.setItem('orderId', false);
        this.$router.push('Dial');
      }

      if (state == 'resend') {
        this.$store.commit('setCurrentOrderId', false);
        localStorage.setItem('orderId', false);
        this.$store.commit('setCurrentStage', false);
        this.resetViewData();
        this.checkData();
        this.connect();
      }

      if (state == 'store') {
        this.$store.commit('resetCurrentData');
        localStorage.setItem('orderId', false);
        this.$store.commit('setCurrentMachine', this.machine);
        this.$store.commit('setCurrentMobile', this.mobile);
        this.$router.push('Shop');
      }

      if (state == 'cancel') {
        console.log(`CANCELING! Sending orderId: ${this.orderId}`)
        axios.post(`https://gimegbrus.no/api/status/${this.orderId}/cancel`)
        .then(res => {
          console.log('AXOIS CANCEL RESPONSE: ', res)
        })
        .catch(err => {
          console.log('AXOIS CANCEL ERROR!: ', err)
        })
        }
    },


    sendMessage (message) {
      console.log(this.connection);
      this.connection.send(message);
      this.to = setTimeout(() => {
        this.cStatus = 'Får ikke svar fra systemet, forsøker igjen...';
        this.connection.close();
        this.cancelActive = true;
      }, 5000)
    },

    resetViewData() {
      this.machine = null,
      this.mobile = null,
      this.itemId = null,
      this.noDiscount = null,
      this.name = null,
      this.order = '',
      this.connection = null, // WS connection object
      this.newConnection = true, // If connection is new.
      this.orderId = ''
      this.error = false
      this.errorMsg = false
      this.previousStage = null
      this.cStatus = 'Kobler til...'
      this.cImage = 'https://gimegbrus.no/api/assets/load.svg'
      this.cancelActive = false
      this.isFree = false
      localStorage.setItem('orderId', false)

    },
    

    checkData() {
      let nd = this.$store.getters.getSelectedItem;
      if (nd.pd) this.noDiscount = nd.pd.disable;
      this.machine = this.$store.getters.getCurrentMachine;
      this.mobile = this.$store.getters.getCurrentMobile;
      this.itemId = this.$store.getters.getSelectedItem.itemId;
      this.name = this.$store.getters.getSelectedItem.name;
      this.order = `{"req":"order", "data": {"payment":"vipps", "mobile":"${this.mobile}", "machine":"${this.machine}", "itemId":"${this.itemId}", "noDiscount":"${this.noDiscount}"}}`
      console.log(' ORDER: ')
      console.log('machine: ', this.machine);
      console.log('mobile: ', this.mobile);
      console.log('itemId: ', this.itemId);
      console.log('noDiscount: ', this.noDiscount);
      console.log('name: ', this.name);
      console.log('Order to send: ', this.order);
      
    },


    free() {
      let item = this.$store.getters.getSelectedItem;
      if (!item) this.isFree = false;
      if (item.pd && item.pd.type == 'free' && !item.pd.disable) this.isFree = true;
      if (item.gd && item.gd.type == 'free') this.isFree = true;

    },

    connect() {

    console.log("Starting connection to WebSocket Server")
    this.connection = new WebSocket("wss://gimegbrus.no/api/ws")

    /* this.sendMessage = function(message) {
      console.log(this.connection);
      this.connection.send(message);
    } */

    this.connection.onmessage = (message) => {
      clearTimeout(this.to);
      console.log('currentStage: ', this.$store.getters.getCurrentStage)

      console.log('Message from server: ', message.data);
      let data;
      try {
        data = JSON.parse(message.data);
      } catch (error) {
        this.error = true;
        this.errorMsg = 'Server returnerte ugyldig data!'
        this.cancelActive = false;
        this.$store.commit('setCurrentStage', 'error');
        console.log('Received JSON data invalid.')
      }
      
      console.log('Parsed Object: ', data);
      
      if (data.machine) this.machine = data.machine;
      if (data.mobile) this.mobile = data.mobile;
      if (data.itemId) this.itemId = data.itemId;
      if (data.orderId) {
        this.orderId = data.orderId;
        this.orderId = data.orderId;
        localStorage.setItem('orderId', data.orderId);
        this.$store.commit('setCurrentOrderId', data.orderId);
      }

      
      


      if (data.code === "payment") {
        this.cStatus = 'Venter på betaling...';
        this.$store.commit('setCurrentStage', 'initiated');
        this.cancelActive = true;
        return;
      }

      if (data.code === "initiated") {
        this.cStatus = 'Venter på betaling...';
        this.cancelActive = true;
      }

      if (data.code === "reserved") {
        this.cStatus = 'Betaling OK, kontakter maskin...';
        this.$store.commit('setCurrentStage', 'reserved');
        this.cancelActive = true;
        return;
      }

      if (data.code === "connecting") {
        this.cStatus = 'Får ikke kontakt med automaten, forsøker igjen, dette kan ta opp til 2 minutter...';
        this.$store.commit('setCurrentStage', 'reserved');
        this.cancelActive = true;
        return;
      }

      if (data.code === "vending") {
        this.cStatus = 'Vender valgt brus!';
        this.$store.commit('setCurrentStage', 'vending');
        this.cancelActive = false;
        return;
      }

      if (data.code === "delivered" || data.code === "captured") {
        this.cImage = 'https://img.pngio.com/vintage-soda-girl-transparent-png-stickpng-vintage-retro-png-woman-400_400.png'
        this.cStatus = 'Brusen er levert!';
        this.$store.commit('setCurrentStage', 'delivered');
        this.cancelActive = false;
        return;
      }

      if (data.code === "canceled") {
        this.cImage = 'https://png.monster/wp-content/uploads/2020/12/explosion-mantra1-0047-811a1e2d-357x370.png'
        this.cStatus = 'Du kansellerte ordren :(';
        this.$store.commit('setCurrentStage', 'canceled');
        this.cancelActive = false;
        return;
      }

      if (data.code === "timeout") {
        this.cImage = 'https://png.monster/wp-content/uploads/2020/12/explosion-mantra1-0047-811a1e2d-357x370.png'
        this.cStatus = 'Ups! Nå var du for treg, ordren timet ut! Forsøk igjen.';
        this.$store.commit('setCurrentStage', 'canceled');
        this.cancelActive = false;
        return;
      }

      if (data.code === "insufficient") {
        this.cImage = 'https://png.monster/wp-content/uploads/2020/12/explosion-mantra1-0047-811a1e2d-357x370.png'
        this.cStatus = 'Oida! Her var det dårlig med penger! Prøv en annen betalingsmetode i Vipps.';
        this.$store.commit('setCurrentStage', 'canceled');
        this.cancelActive = false;
        return;
      }

      if (data.code === "noResponse") {
        this.cImage = que;
        this.cStatus = 'Oisann! Automaten gav ikke svar fra seg! Om du ikke fikk brusen din, vil du automatisk få refundert pengene innen 24 timer, eller så snart vi får kontakt med automaten :)';
        this.$store.commit('setCurrentStage', 'canceled');
        this.cancelActive = false;
        return;
      }

      if (data.code === "frequency") {
        this.cImage = drunk;
        this.cStatus = 'Oida! Nå ble det vel litt mye brus? Forsøk igjen om noen minutter :)';
        this.$store.commit('setCurrentStage', 'canceled');
        this.cancelActive = false;
        return;
      }

      if (data.code === "error") {
        this.error = true;
        this.errorMsg = data.message;
        this.$store.commit('setCurrentStage', 'canceled');
        this.cancelActive = false;
        return;
      }
    }


    this.connection.onclose = () => {
      console.log("Connection closed.");
      this.cancelActive = false;

      let prevStage = this.$store.getters.getCurrentStage;
      if (prevStage == 'delivered' || prevStage == 'canceled') return;

      this.cImage = 'https://gimegbrus.no/api/assets/load.svg';
      this.cStatus = 'Mistet kontakten med systemet, forsøker å koble til igjen.';
      this.$store.commit('setCurrentStage', 'lost');
      this.cancelActive = false;

      setTimeout(() => {
        this.connect();
      }, 10000)
        return;

    }

    this.connection.onopen = () => {
      console.log('currentStage: ', this.$store.getters.getCurrentStage)
      console.log("Successfully connected to the echo websocket server...");
      
      if (this.newConnection) {
        console.log("Sending new order request...");
        this.sendMessage(this.order);
        console.log('Sendt new order request: ', this.order);
        this.newConnection = false;
        return;
      }
      console.log('Sending get status message...')
      if (!this.orderId) {
        this.orderId = localStorage.getItem('orderId');
      }
      this.sendMessage(`{"req":"status", "orderId":"${this.orderId}"}`);

    }
    },





  },




}
</script>

<style scoped>

#loading {
  width: 100%;
}

#vending {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
#cmPng {
  flex-basis: 200px;
}

#center {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 50px;
  
}

h1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

#retry {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

#error {
  margin-top: auto;
  margin-bottom: auto;
}

#errorPng {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

#loadPng {
  width: 50%;
}

</style>
